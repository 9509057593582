import React from "react";

import "./SpaceRight.css";

export default function SpaceRight({ name, image, info, link }) {
  return (
<div className="space_right">
  <div className="space-container_right">
    <div className="space-text_right">
      <div className="space-text_cont">
        <h3 className="container-h3_right">{name.toUpperCase()}</h3>
        <p className="container-pgh_right">{info}</p>
        <button className="custom-button">{link}</button> 
      </div>
    </div>
    <div className="space-image_right">
      <img src={image} alt={`Grupo Requiez - ${name}`} />
    </div>
  </div>
</div>

  );
}
