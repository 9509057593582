import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import image1 from "../../banners/1.webp";
import image2 from "../../banners/2.webp";
import image3 from "../../banners/3.webp";
import image4 from "../../banners/4.webp";
import image5 from "../../banners/5.webp";

class Banner1 extends Component {
  render() {
    const images = [image1, image2, image3, image4, image5];
    const names = ['Image 1', 'Image 2', 'Image 3', 'Image 4', 'Image 5'];
    //const links = ['https://link1.com', 'https://link2.com', '/tothelastdetail', 'https://link4.com', 'https://link5.com'];
    const thirdImageLink = '/tothelastdetail'; // Link para la tercera imagen


    return (
      <Carousel
        nextIcon={''}
        prevIcon={''}
        nextLabel={''}
        prevLabel={''}
        style={{ width: '100%' }}
      >
        {images.map((image, index) => (
          <Carousel.Item key={index}>
            {index === 2 ? ( // Solo para la tercera imagen (índice 2)
              <a href={thirdImageLink} target="_blank" rel="noopener noreferrer">
                <img
                  className="d-block w-100"
                  src={image}
                  alt={names[index]}
                />
              </a>
            ) : (
              <img
                className="d-block w-100"
                src={image}
                alt={names[index]}
              />
            )}
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }
}







export default Banner1;

// class Banner1 extends Component {
//   render() {
//     const images = [image1, image2, image3, image4, image5];
//     const names = ['Image 1', 'Image 2', 'Image 3', 'Image 4', 'Image 5'];

//     return (
//       <Carousel
//         nextIcon={''}
//         prevIcon={''}
//         nextLabel={''}
//         prevLabel={''}
//         style={{ width: '100%' }}
//       >
//         {images.map((image, index) => (
//           <Carousel.Item key={index}>
//             <img
//               className="d-block w-100"
//               src={image}
//               alt={names[index]}
//             />
//           </Carousel.Item>
//         ))}
//       </Carousel>
//     );
//   }
// }

// export default Banner1;