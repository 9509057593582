import React, { Component, Fragment } from "react";

import PrimaryProduct from "../components/PrimaryProduct/PrimaryProduct";
import Products from "../components/Products/Products";
import Footer from "../components/Footer/Footer";
import Banner2 from "../components/Banner/Banner2";
import Banner1 from "../components/Banner/Banner1";

export class Home extends Component {
  render() {
    return (
      <Fragment>
        <Banner1 />
        <PrimaryProduct num="7" />
        <Products id="products" />
        <Banner2 />
        <Footer />
      </Fragment>
    );
  }
}

export default Home;
