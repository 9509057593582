import React, { Component, Fragment } from "react";
import BannerMW from "../components/Banner/BannerMW";
import Footer from "../components/Footer/Footer";
import Mw from "../components/Mw/Mw";


class History extends Component {
  render() {
    return (
      <Fragment>
        <BannerMW />
        <Mw/>
        <Footer />
      </Fragment>
    );
  }
}

export default History;
