import React, { Component, Fragment } from "react";
import Footer from "../components/Footer/Footer";
import BannerOW from "../components/Banner/BannerOW";
import Ow from "../components/Ow/Ow";




class OkamuraWay extends Component {
  render() {
    return (
      <Fragment>
        <BannerOW />
        <Ow/>
        <Footer />
      </Fragment>
    );
  }
}

export default OkamuraWay;
