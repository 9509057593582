import React, { Component, Fragment } from "react";
import BannerGW from "../components/Banner/BannerGW";
import Footer from "../components/Footer/Footer";
import Gw from "../components/Gw/Gw";


class History extends Component {
  render() {
    return (
      <Fragment>
        <BannerGW />
        <Gw/>
        <Footer />
      </Fragment>
    );
  }
}

export default History;
