import React, { Component } from "react";

import img1 from "../../banners/bannerContacto.webp";



import "./Banner3.css";


class BannerTL extends Component {
  render() {
    const { images, names } = this.props;

    return (
      <section>
        <img src={img1} alt="banco" className="img1" />
      </section>
    );
  }
}

export default BannerTL;



