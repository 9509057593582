import React, { Component, Fragment } from "react";
import BannerTL from "../components/Banner/BannerTL";
import Footer from "../components/Footer/Footer";
import Tl from "../components/Tl/Tl";


class History extends Component {
  render() {
    return (
      <Fragment>
        <BannerTL />
        <Tl/>
        <Footer />
      </Fragment>
    );
  }
}

export default History;
