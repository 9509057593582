import React, { Component, Fragment } from "react";
import ViewProduct from "../components/ViewProduct/ViewProduct";
import Footer from "../components/Footer/Footer";

class ProductView extends Component {
  render() {
    return (
      <Fragment>
        <ViewProduct />
        <Footer />
      </Fragment>
    );
  }
}

export default ProductView;
