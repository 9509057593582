import React, { Component, Fragment } from "react";
import img1 from "../../images/gw1.webp";
import img2 from "../../images/gw2.webp";
import img3 from "../../images/gw3.webp";
import img4 from "../../images/gw4.webp";
import img5 from "../../images/gw5.webp";
import img6 from "../../images/gw6.webp";
import img7 from "../../images/gw7.webp";
import img8 from "../../images/gw8.webp";
import img9 from "../../images/gw9.webp";
import img10 from "../../images/gw10.webp";
import img11 from "../../images/gw11.webp";
import img12 from "../../images/gw12.webp";
import img13 from "../../images/gw13.webp";

import SpaceGw from "../SpaceGw/SpaceGw";

import "./Gw.css";

class GW extends Component {
  render() {
    return (
      <Fragment>
        <div className="img-container">
          <img src={img1} alt="" />
        </div>

        <div className="gw-container">
          <div>
            <div className="img-container3">
              <img src={img2} alt="" />
            </div>
          </div>
          <div>
            <h2 className="gw-titulo">
              Declaración de Okamura sobre la neutralidad de Carbono en 2050
            </h2>
            <p className="gw-text">
              Grupo Okamura, aspira a ser neutro en carbono para 2050. Como
              meta intermedia, nos hemos fijado el objetivo de reducir las
              emisiones de CO2 en un 50% respecto a los niveles de 2020 para
              2030.
            </p>
           
          </div>
        </div>
        <h2 className="gw-titulo2">Hacia la neutralidad de carbono en 2050</h2>

        <div className="gw-container2">
          <div>
            <div className="img-container3">
              <img src={img3} alt="" />
            </div>
          </div>
          <div>
            <p className="gw-text2">
              En 1997, implementamos nuestra iniciativa GREEN WAVE, que ahora
              incorpora nuestro objetivo de alcanzar la neutralidad de carbono
              en 2050. Esta iniciativa pasa ahora a la siguiente fase.
            </p>
            <p className="gw-text2">
              Como el Grupo Okamura aspira a ser neutro en emisiones de carbono
              para 2050, hemos fijado como meta intermedia reducir las emisiones
              de CO2 en un 50%, en comparación con los niveles de 2020, para
              2030. Para lograr este objetivo, reforzaremos aún más nuestras
              actividades y desarrollaremos iniciativas desde una nueva
              perspectiva para contribuir a la realización de una sociedad libre
              de carbono.
            </p>
          </div>
        </div>
        <h2 className="gw-titulo2">Nuestras acciones</h2>
        <div className="img-container4">
          <img src={img4} alt="" />
          <img src={img5} alt="" />
          <img src={img6} alt="" />
        </div>
        <h2 className="gw-titulo2">Green Wave</h2>

        <div className="gw-container">
          <div>
            <div className="img-container5">
              <img src={img7} alt="" />
            </div>
          </div>
          <div>
            <h2 className="gw-titulo">¿Qué es el GREEN WAVE de Okamura? </h2>
            <p className="gw-text3">
              Desde 1997, Grupo Okamura ha ido ampliando su gama de productos
              que respetan el medio ambiente, estableciendo sus propios
              estándares medio ambientales para los productos basados en el
              concepto de la "GREEN WAVE”.
            </p>
            <p className="gw-text3">
              Con el objetivo de llegar a ser neutros en carbono, a partir de
              2021 introduciremos el pensamiento de diseño circular en el
              desarrollo de productos y un programa de compensación de carbono
              para mejorar en gran medida nuestras iniciativas GREEN WAVE.
            </p>
          </div>
        </div>

        <h2 className="gw-titulo3">Criterios del producto Green Wave</h2>
        <div className="img-container6">
          <img src={img8} alt="" />
        </div>

        <h2 className="gw-titulo4">
          Okamura ha obtenido certificaciones de organizaciones externas por
          estas iniciativas:
        </h2>
        <h2 className="gw-titulo5">Indoor Advantaje, ISO 14001, FSC® </h2>
        <h2 className="gw-titulo2">DISEÑO CIRCULAR</h2>

        <div className="gw-container">
          <div>
            <div className="img-container7">
              <img src={img5} alt="" />
            </div>
          </div>
          <div>
            <p className="gw-text3">
              Okamura, piensa en términos de economía circular. Nuestro objetivo
              es hacer un uso más eficaz de nuestros limitados recursos y
              minimizar los residuos a lo largo de todo el ciclo de vida del
              producto, desde la planificación y el diseño hasta la venta, el
              mantenimiento, la reutilización y el reciclaje. Al tener en cuenta
              el medio ambiente en nuestra toma de decisiones, podemos
              contribuir a la creación de una sociedad más sostenible.
            </p>
          </div>
        </div>

        <div className="img-container6">
          <img src={img9} alt="" />
        </div>
        <div className="img-container6">
          <img src={img11} alt="" />
        </div>
        <div className="img-container6">
          <img src={img12} alt="" />
        </div>
        <div className="img-container6">
          <img src={img13} alt="" />
        </div>

        <h2 className="gw-titulo2">
          PROGRAMA DE COMPENSACIÓN DE EMISIONES DE CARBONO
        </h2>

        <div className="gw-container">
          <div>
            <div className="img-container7">
              <img src={img6} alt="" />
            </div>
          </div>
          <div>
            <p className="gw-text3">
              La "compensación de carbono" es el concepto de equilibrar las
              emisiones inevitables de CO2 y otros gases de efecto invernadero
              en nuestra vida diaria y actividades en la economía, primero
              haciendo esfuerzos para re- ducir las emisiones tanto como sea
              posible y luego invirtiendo en activi- dades de reducción de gases
              que sean proporcionales a la cantidad que inevitablemente se
              emiten. Invertir en actividades de reducción de gases que
              coincidan con la cantidad de los emitidos. (Tomado del sitio web
              del Ministerio del Medio Ambiente).
            </p>
          </div>
        </div>

        <h2 className="gw-titulo6">
          Okamura Corporation, calcula correctamente las emisiones de CO2 a lo
          largo del ciclo de vida del producto, incluida la fabricación, el
          transporte y la eliminación, proporcionando a los clientes créditos de
          emisión equivalentes a la cantidad de CO2 emitido. Al comprar estos
          productos, los clientes contribuyen a las actividades de reducción de
          gases de efecto invernadero.
        </h2>

        <div className="img-container6">
          <img src={img10} alt="" />
        </div>
      </Fragment>
    );
  }
}

export default GW;
