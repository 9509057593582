import React, { Component, Fragment } from "react";
import img1 from "../../images/ow1.webp";
import img2 from "../../images/ow2.webp";
import img3 from "../../images/ow3.webp";
import "./Ow.css";
import SpaceOw from "../SpaceOw/SpaceOw";
import SpaceRightOw from "../SpaceRightOw/SpaceRightOw";

class Ow extends Component {
  render() {
    return (
      <Fragment>
        <h2 className="ow-titulo">¿Qué es Okamura Way?</h2>

        <SpaceOw
          image={img1}
          style={{ whiteSpace: "pre-line" }}
          info={`
      Okamura Way abarca la Misión de Okamura (nuestro enfoque de gestión),
      la Declaración de Okamura (lo que queremos ser) y los Fundamentos de Okamura 
      (los valores que apreciamos). En el centro de ellos está nuestra perspectiva
      "Donde las personas pueden prosperar."  

  `}
          info2={
            "Nuestro objetivo es garantizar que todos los empleados de Okamura, actúen con una mentalidad coherente, utilizando el Okamura Way como guía en sus actividades empresariales diarias y en la toma de decisiones."
          }
          info3={
            "De este modo, nuestra individualidad, nuestro trabajo, las personas con las que trabajamos y la sociedad, prosperarán. En última instancia, nos ayudará a contribuir a la realización de una sociedad en donde todas las personas puedan trabajar y vivir con vitalidad y sonrisas."
          }
        />

        <SpaceOw
          image={img2}
          info='El término "Donde las personas pueden prosperar" expresa la idea de que cada persona trabaja en su mejor momento cuando demuestra su individualidad y experiencia, así como un estado de co-creación y creación de nuevo valor a través de buenas relaciones con todas las personas que le rodean.'
          info2='En otras palabras, si cada uno de nosotros puede sacar lo mejor de sí mismo, y sacar lo mejor de las demás, entonces nuestro trabajo, nuestra empresa y la sociedad en su conjunto, cobrarán vida. Basándonos en esta idea de "Donde las personas pueden prosperar”, en Okamura estamos comprometidos en contribuir a la sociedad a través de la realización de una sociedad sostenible.'
        />

        <h2 className="ow-titulo">Misión de Okamura</h2>
        <p className="ow-bold">
          "Contribuir a la sociedad creando entornos en los que las personas
          puedan prosperar con ideas enriquecedoras y una calidad confiable".
        </p>
        <p className="ow-text">
          Dentro de nuestro variable sistema de valores actual, en el que la
          sustentabilidad es cada vez más importante, la aportación de cada
          individuo conducirá a la solución de los problemas sociales.
        </p>
        <p className="ow-text">
          Basándonos en esta creencia y en nuestro sentido del propósito
          corporativo, seguiremos creando espacios.
        </p>
        <h2 className="ow-titulo">Declaración de Okamura</h2>
        <p className="ow-bold">"Amar a las personas, crear espacios".</p>
        <p className="ow-text">
          Para aumentar el número de personas que pueden vivir a su manera y
          crear una sociedad llena de sonrisas, Okamura seguirá apreciando a las
          personas y creando entornos en los que puedan prosperar.
        </p>
        <p className="ow-text">
          Desde nuestra fundación, hemos desarrollado nuestro negocio con
          grandes aspiraciones de fabricación, y hemos creado una gran variedad
          de espacios, desde oficinas e instalaciones comerciales y logísticas,
          hasta hospitales y escuelas.
        </p>
        <p className="ow-text">
          En la raíz de todo esto siempre ha estado la sincera necesidad de
          "amar a las personas".
        </p>
        <h2 className="ow-titulo2">Fundamentos de Okamura -SMILE-</h2>

        <SpaceRightOw
          name="Para hacer sonreír a todas las personas que trabajan con nosotros"
          image={img3}
          info="Seguiremos amando a las personas y creando lugares donde puedan prosperar."
          info2="Cinco enfoques que cada empleado pueda utilizar como base de sus acciones diarias para hacer realidad la Misión de Okamura."
        />

        <h2 className="ow-titulo">Declaración de Okamura</h2>
        <p className="ow-text">
          Nuestro Espíritu Fundador, Filosofía Corporativa y Lema, son raíz de
          nuestra cultura.
        </p>
        <p className="ow-bold2">Espíritu Fundador</p>
        <p className="ow-bold2">Industria cooperativa</p>
        <p className="ow-text">
          En Okamura Manufacture (Okamura), fundada por jóvenes Ingenieros que
          habían perdido su trabajo tras la guerra, todos los empleados son
          iguales y forman parte de un equipo con el objetivo común de hacer que
          la empresa prospere. "Industria Cooperativa" representa la Filosofía
          que ha estado vigente desde la fundación de la empresa: producir
          grandes resultados mediante la cooperación de todos estos iguales.
        </p>
        <p className="ow-bold2">Filosofía de la empresa</p>
        <p className="ow-bold2">
          Creación inovadora, Cooperación, Conciencia de costos, Ahorro para el
          futuro, Responsabilidad Social.
        </p>
        <p className="ow-text">
          Nuestra Filosofía Corporativa expresa la base de gestión para el
          crecimiento de Okamura, y consta de cinco palabras inculcadas del
          espíritu de la fundación de la empresa. "Creación innovadora"
          significa inventar productos de categoría mundial que aún no existen
          en otros lugares, y "Cooperación" es esencial para lograrlo. "Ser
          conscientes de los costos" y "Ahorrar para el futuro" son inversiones
          para el crecimiento. Por último, "Responsabilidad social" resume el
          espíritu de devolver a la sociedad lo que apoya a nuestra empresa.
        </p>

        <p className="ow-text">La calidad se paga sola.</p>
        <p className="ow-text2">
          Nuestro lema transmite la alta calidad de los productos de Okamura y
          nuestra búsqueda de esta calidad. Nuestra política básica de gestión
          establece la necesidad de tecnología y calidad competitivas a nivel
          internacional, un compromiso con la investigación sin escatimar
          esfuerzos y el suministro de buenos productos a bajo costo, lo que
          refleja la fuerte conciencia de la necesidad de una fabricación
          superior que hemos mantenido desde los inicios de la empresa.
        </p>
      </Fragment>
    );
  }
}

export default Ow;
