import React, { Component, Fragment } from "react";
import img1 from "../../images/tl1.webp";
import img2 from "../../images/tl2.webp";
import img3 from "../../images/tl3.webp";
import img4 from "../../images/tl4.webp";
import img5 from "../../images/tl5.webp";
import img6 from "../../images/tl6.webp";
import img7 from "../../images/tl7.webp";
import img8 from "../../images/tl8.webp";


import "./Tl.css";


class Tl extends Component {
  render() {
    return (
      <Fragment>
            <div className="img-containerTl">
              <img src={img1} alt="" />
              <img src={img2} alt="" />
              <img src={img3} alt="" />
              <img src={img4} alt="" />
              <img src={img5} alt="" />
              <img src={img6} alt="" />
              <img src={img7} alt="" />
              <img src={img8} alt="" />
            </div>
            
      </Fragment>
    );
  }
}

export default Tl;
