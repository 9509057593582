import React, { Fragment, useState, useEffect } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useParams } from "react-router";

import data from "../../data-new.json";
import "./ViewProduct.css";

const telas = data.data;
const qrs = data.data;
const cert = data.data; 

const ViewProduct = () => {
  const num1 = useParams().id;
  const num = num1 - 1;

  const [image, setImage] = useState("");

  const handleClick = (img) => {
    setImage(img.target.src);
  };

  useEffect(() => {
    setImage("");
  }, [num]);

  return (
    <Fragment>
      <div className="container-fluid ProductView">
        <h2 className="ProductView__info-title">
          {data.data[num].Producto.toUpperCase()}
        </h2>
        <figure className="image-container">
          <img
            className="ProductView__img"
            src={image ? image : data.data[num].img}
            alt={data.data[num].Producto}
          />
          <div className="ProductView__img-carousell">
            {telas[num].iGallery.map(({ id, img }) => {
              return (
                <span key={id} onClick={handleClick}>
                  <img
                    className="ProductView__img-carousell-min"
                    src={img}
                    alt={img}
                  />
                </span>
              );
            })}
          </div>
        </figure>
        <div className="ProductView__info">
          <p className="ProductView__info-description">
            {data.data[num].Descripcion}
          </p>

          <h2 className="ProductView__info-specs">Especificaciones</h2>
          <div className="ProductView__info-accordion">
            <Accordion className="accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="accordion-title">
                  Características
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <Typography className="accordion-desc">
                  {data.data[num].Caracteristicas}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className="accordion-title">Medidas</Typography>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <Typography className="accordion-desc">
                  {data.data[num].Medidas}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className="accordion-title">Materiales</Typography>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <Typography className="accordion-desc">
                  {data.data[num].Materiales}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className="accordion-title">Color</Typography>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <Typography className="accordion-desc">
                  {telas[num].Colores.map(({ color, img }) => (
                    <span key={img} className="accordion-colors">
                      <img
                        className="img-certificate telas"
                        src={img}
                        alt="color"
                      />
                      <span>{color}</span>
                    </span>
                  ))}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className="accordion-title">
                  Certificaciones y Premios
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <Typography className="accordion-desc">
                  {/* <img
                    className="img-certificate"
                    src={data.data[num].Certificaciones}
                    alt="Certificado"
                  /> */}

                  {cert[num].Certificaciones.map(({ id, img }) => (
                 
                      <img
                        key={id}
                        className="img-certificate"
                        src={img}
                        alt="Certificado"
                      />
                   
                  ))}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className="accordion-title">
                  Garantía Extendida
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <Typography className="accordion-desc">
                  Garantía de 10 años en defectos de fábrica.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion className="accordion">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className="accordion-title">
                  Realidad Aumentada
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="accordion-details">
                <Typography className="accordion-desc">
                  Escanea el código con tu cámara del celular <br />
                  {/*<img
                    className="img-certificate"
                    src={data.data[num].QR}
                    alt="Qr"
                  />*/}
                  {qrs[num].Modelos.map(({ ra, raImg }) => (
                    <span key={raImg} className="accordion-colors">
                      <img
                        className="img-certificate telas"
                        src={raImg}
                        alt="color"
                      />
                    </span>
                  ))}
                  <Typography
                    className="accordion-desc"
                    style={{ display: "block" }}
                  >
                    Sólo disponible en iOS*
                  </Typography>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <img className="imgIzq" src={data.data[num].ImgIzq} alt="1" />
        <img className="imgDer" src={data.data[num].ImgDer} alt="2" />
      </div>
    </Fragment>
  );
};

export default ViewProduct;
