import React from "react";

import "./SpaceRightMw.css";

export default function SpaceRightMw({ name, image, info, info2, link }) {
  return (
<div className="space_right">
  <div className="space-container_right">
    <div className="space-text_right">
      <div className="space-text_cont">
        <h3 className="container-h3_right">{name.toUpperCase()}</h3>
        <p className="container-pgh_right">{info}</p>
        {info2 && (
              <p className="container-pgh_right2">
                <br />
                {info2}
              </p>
            )}
      </div>
    </div>
    <div className="space-image_right">
      <img src={image} alt={`Grupo Requiez - ${name}`} />
    </div>
  </div>
</div>

  );
}
