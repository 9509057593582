import React, { Component, Fragment } from "react";
import img1 from "../../images/mw1.webp";
import img2 from "../../images/mw2.webp";
import img3 from "../../images/mw3.webp";
import img4 from "../../images/mw4.webp";
import img5 from "../../images/mw5.webp";
import img6 from "../../images/mw6.webp";
import img7 from "../../images/mw7.webp";
import img8 from "../../images/mw8.webp";
import img9 from "../../images/mw9.webp";
import img10 from "../../images/mw10.webp";
import img11 from "../../images/mw11.webp";
import img12 from "../../images/mw12.webp";
import img13 from "../../images/mw13.webp";
import img14 from "../../images/mw14.webp";
import img15 from "../../images/mw15.webp";
import img16 from "../../images/mw16.webp";
import img17 from "../../images/mw17.webp";
import img18 from "../../images/mw18.webp";

import "./Mw.css";
import SpaceMw from "../SpaceMw/SpaceMw";
import SpaceRightMw from "../SpaceRightMw/SpaceRightMw";

class Mw extends Component {
  render() {
    return (
      <Fragment>
        <SpaceRightMw
          name="Innovando para un mejor lugar de trabajo"
          image={img1}
          info="Desde el concepto hasta la finalización, los productos Okamura están llenos de espíritu creativo. Incluso los detalles más pequeños superan los límites del diseño convencional, por lo que creamos algo totalmente nuevo y único, una y otra vez. Todo con el objetivo final de hacer evolucionar el lugar de trabajo, creando entornos más productivos, más creativos y más felices."
        />
        <SpaceMw
          name="La creación comienza aquí"
          image={img2}
          info="Nuestra División de Desarrollo de Productos gestiona los nuevos proyectos en todas sus fases, desde las primeras ideas hasta la realización del producto final."
          info2="Se aseguran de que cada proyecto alcanze su potencial."
        />
        <div className="mw-titulo1">
          <div>
            <h2 className="mw-titulo">Investigación de campo</h2>
            <div className="img-containerMw">
              <img src={img3} alt="Investigación de campo image" />
            </div>
            <p className="mw-text">
              Recopilamos una gran cantidad de datos sobre las tendencias
              actuales y la orientación del mercado. Visitamos regularmente
              ferias comerciales de diversas industrias, desde muebles,
              automóviles hasta materiales, para profundizar nuestra
              experiencia. Lo que lleva a una actualización constante de las
              líneas de productos.
            </p>
          </div>
          <div>
            <h2 className="mw-titulo">Estudios</h2>
            <div className="img-containerMw">
              <img src={img4} alt="Investigación de campo image" />
            </div>
            <p className="mw-text">
              Mediante el uso de datos científicos adquiridos numerosos
              experimentos, buscamos constantemente formas de desarrollar los
              asientos óptimos.
            </p>
            <p className="mw-text">
              Por ejemplo, nuestro simulador de asiento ergonómico analiza la
              distribución de la presión en el asiento y el respaldo en función
              de variables como el sexo y la altura del usuario.
            </p>
          </div>
        </div>

        <SpaceMw
          name="Colaboración"
          image={img5}
          info="Colaboramos con instituciones académicas externas para explorar una amplia gama de temas, como la relación entre las personas y el mobiliario, las tecnologías de vanguardia y el desarrollo de nuevos materiales. También disponemos de espacios de cocreación donde organizamos talleres y seminarios impartidos por profesionales, y absorbemos continuamente nuevos conocimientos."
        />
        <SpaceRightMw
          name="Planificación"
          image={img6}
          info="Cuando iniciamos un proyecto, formamos nuestro equipo con estrategia e intención, de tal manera que llene las brechas de un departamento a otro. Trabajando juntos a la perfección, buscamos una diversidad de aportaciones y perspectivas a medida que afinamos el alcance del proyecto."
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <hr
            style={{
              color: "gray",
              backgroundColor: "gray",
              height: 1,
              width: "97%",
              margin: 0,
              padding: "1px 0",
            }}
          />
          {/* Content of your component */}
        </div>

        <SpaceMw
          name="Hacer que las ideas cobren vida"
          image={img7}
          info="En función del alcance del proyecto, nuestro equipo de diseño de productos empieza a hacer bocetos de diseño, modelos de diseño y prototipos, y después desarrolla nuevos materiales y mecanismos estructurales con el resto del equipo del proyecto cuando es necesario."
        />
        <SpaceRightMw
          name="Diseño"
          image={img8}
          info="Nuestro equipo de diseño comienza con una clara comprensión de las necesidades del usuario y de cómo la ingeniería, la elección de materiales y las capacidades de diseño de Okamura pueden aprovecharse para satisfacer a las personas en el lugar donde trabajan. Luego, cada etapa del diseño -desde los bocetos a mano y el modelo CAD, hasta la creación de prototipos y la supervisión de la producción- atiende a las necesidades de personas reales en espacios de trabajo reales."
        />
        <div className="mw-titulo1">
          <div>
            <h2 className="mw-titulo">Colores de Materiales y acabados</h2>
            <div className="img-containerMw">
              <img src={img9} alt="Investigación de campo image" />
            </div>
            <p className="mw-text">
              Nuestro equipo especializado de expertos en CMF trabaja con el
              equipo de diseño de productos para sacar al mercado los productos
              finales más relevantes. Nuestros productos se componen únicamente
              de los materiales más bellos y de mayor calidad.
            </p>
          </div>
          <div>
            <h2 className="mw-titulo">Reevaluación</h2>
            <div className="img-containerMw">
              <img src={img10} alt="Investigación de campo image" />
            </div>
            <p className="mw-text">
              Los miembros de nuestro equipo se mantienen en constante
              comunicación, eliminando posibles problemas y garantizando todas
              las mejoras posibles.
            </p>
          </div>
        </div>

        <SpaceMw
          name="Producción de Pruebas"
          image={img11}
          info="Los artesanos especializados en la fabricación de prototipos de nuestro departamento de fabricación, crean modelos y prototipos a mano, lo que permite realizar exhaustivas críticas de diseño y pruebas de resistencia. Retocan repetidamente los prototipos ajustando pequeños detalles para lograr la máxima funcionalidad y belleza."
        />

        <div className="mw-titulo1">
          <div>
            <div className="img-containerMw">
              <img src={img12} alt="Investigación de campo image" />
            </div>
            <h2 className="mw-titulo">Evaluación de los Mockups</h2>

            <p className="mw-text">
              Basándose en el prototipo finalizado, los jefes de planificación y
              diseño se reúnen con los jefes de fabricación. Juntos determinan
              la fabricación real del producto.
            </p>
          </div>
          <div>
            <div className="img-containerMw">
              <img src={img13} alt="Investigación de campo image" />
            </div>
            <h2 className="mw-titulo">Análisis</h2>

            <p className="mw-text">
              La resistencia de un producto se verifica primero mediante
              análisis informáticos y a través de pruebas de prototipos físicos
              en una plataforma de prueba especializada. Por último, todas las
              sillas Okamura se fabrican para que se adapten perfectamente a la
              forma humana y, por tanto, son probadas por ella.
            </p>
          </div>
        </div>

        <SpaceMw
          name="Consideración para la fabricación"
          image={img14}
          info="El departamento de fabricación sigue perfeccionando su proceso y concepto de montaje durante meses antes de finalizar el producto. Buscan la forma más eficaz de ensamblarlo para minimizar el tiempo del proceso de montaje. Cada componente, cada estructura, cada mecanismo del cuerpo del producto se estudia cuidadosamente, y luego se vuelve a estudiar. Especialmente, las piezas más complejas, como las palancas ajustables de los asientos, requieren una consideración especial."
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <hr
            style={{
              color: "gray",
              backgroundColor: "gray",
              height: 1,
              width: "97%",
              margin: 0,
              padding: "1px 0",
            }}
          />
          {/* Content of your component */}
        </div>

        <SpaceMw
          name="Un orgulloso linaje de fabricación artesanal"
          image={img15}
          info="El departamento de fabricación sigue perfeccionando su proceso y concepto de montaje durante meses antes de finalizar el producto. Buscan la forma más eficaz de ensamblarlo para minimizar el tiempo del proceso de montaje. Cada componente, cada estructura, cada mecanismo del cuerpo del producto se estudia cuidadosamente, y luego se vuelve a estudiar. Especialmente, las piezas más complejas, como las palancas ajustables de los asientos, requieren una consideración especial."
        />
        <SpaceRightMw
          name="Comprobación"
          image={img16}
          info="Probamos el rendimiento de nuestros productos utilizando nuestras propias normas industriales Okamura, más estrictas que las normas industriales japonesas y otras normas, incluida la BIFMA."
          info2="A lo largo de las fases de prueba, evaluamos la seguridad de nuestros productos, examinando repetidamente la durabilidad de cada componente."
        />
        <div className="mw-titulo1">
          <div>
            <div className="img-containerMw">
              <img src={img17} alt="Investigación de campo image" />
            </div>
            <h2 className="mw-titulo">Fabricación</h2>

            <p className="mw-text">
              No sólo diseñamos nuestros productos en Japón, sino que también
              trabajamos para fabricar todos los componentes posibles en el
              país. La soldadura, el moldeado, la costura, el revestimiento y el
              montaje se realizan siguiendo los estándares más exigentes en
              nuestros campus de fabricación.
            </p>
          </div>
          <div>
            <div className="img-containerMw">
              <img src={img18} alt="Investigación de campo image" />
            </div>
            <h2 className="mw-titulo">Control de Calidad</h2>

            <p className="mw-text">
              En cada paso del proceso, garantizamos la calidad y la precisión,
              utilizando tecnología punta para realizar comprobaciones visuales
              y táctiles. Esto requiere el trabajo manual de artesanos
              experimentados, que trabajan con eficiencia y eficacia para
              garantizar los más altos estándares.
            </p>
          </div>
        </div>
        <h2 className="mw-titulo">Invirtiendo en la próxima generación</h2>
        <p className="mw-text">
          Animamos a nuestros empleados a que se presenten al Examen Nacional de
          Aptitud Profesional y a la Certificación. Ellos transmiten sus
          conocimientos tecnológicos, perfeccionados a lo largo de sus carreras,
          a sus compañeros de trabajo.
        </p>
        <p className="mw-text">
          Aunque automaticemos nuestros procesos de fabricación, siempre
          necesitaremos artesanos capaces de crear algo de la nada; que posean
          no sólo conocimientos fundamentales de fabricación, sino también una
          imaginación despierta; y que comprendan, sin lugar a dudas, que la
          calidad se paga por sí misma.
        </p>
        <p className="mw-text">
          Incluso tenemos nuestro propio centro de formación de habilidades
          técnicas, que atiende tres necesidades diferentes: formar a la próxima
          generación, perfeccionar las habilidades de los técnicos y mejorar la
          capacidad de gestión de proyectos.
        </p>
      </Fragment>
    );
  }
}

export default Mw;
