import React, { Component, Fragment } from "react";
import BannerC from "../components/Banner/BannerC";
import Footer from "../components/Footer/Footer";
import Con from "../components/Con/Con";

class History extends Component {
  render() {
    return (
      <Fragment>
        <BannerC />
        <Con />
        <Footer />
      </Fragment>
    );
  }
}

export default History;
