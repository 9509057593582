import React from "react";

import "./SpaceMw.css";

export default function SpaceMw({ name, image, info, info2, info3, color }) {
  return (
    <div className="space" style={{ backgroundColor: color }}>
      <div className="space-container">
        <div className="space-image">
          <img src={image} alt={`Grupo Requiez - ${name}`} />
        </div>
        <div className="space-text">
          <div>
            {name && <h3 className="container-h3">{name.toUpperCase()}</h3>}
            <p className="container-pgh">{info}</p>
            {info2 && (
              <p className="container-pgh">
                <br />
                {info2}
              </p>
            )}
            {info3 && (
              <p className="container-pgh">
                <br />
                {info3}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
