import React, { Component } from "react";

import img1 from "../../banners/img1.webp";
import img2 from "../../banners/img2.webp";

import "./Banner2.css";


class Banner2 extends Component {
  render() {
    const { images, names } = this.props;

    return (
      <section className="prods">
        <img src={img1} alt="banco" className="img1" />
        <img src={img2} alt="banco" className="img2" />
      </section>
    );
  }
}

export default Banner2;
