import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./Header.css";

class Header extends Component {
  handleClickTop = () => {
    const halfHeight = document.documentElement.scrollHeight / 2;
    const topPosition = halfHeight - 225; // 100 is the amount of pixels to scroll above the half height
    window.scrollTo(0, topPosition);
  };

  handleClickBottom = () => {
    window.scrollTo(0, document.documentElement.scrollHeight);
  };

  render() {
    return (
      <div className="container-fluid">
        <header>
          <div className="row header--container_info">
            <div className="row header-image">
              <figure className="">
                <img
                  src="/img/LogoOkamura.webp"
                  className="Okamura"
                  alt="Logo Okamura"
                  onClick={() => {
                    console.log("Image clicked");
                    window.location.href = "/";
                  }}
                />
              </figure>
            </div>
            <div className="row header-info">
              <nav className="header--navbar">
                <ul className="header--navbar-list">
                  <li>
                    <a onClick={this.handleClickTop}>Productos</a>
                  </li>
                  <li>
                    <Link to="/History">Nosotros</Link>{" "}
                  </li>
                  <li>
                    <a target="_blank" href="/img/Catálogo Okamura México.pdf">
                      Catálogo
                    </a>
                  </li>
                  <li>
                    <Link to="/Greenwave">Sustentabilidad</Link>{" "}
                  </li>
                  <li>
                    <a onClick={this.handleClickBottom}>Contacto</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default Header;
