import React, { Component } from "react";
import img1 from "../../banners/primProduct.webp";
import "./PrimaryProduct.css";

import data from "../../data-new.json";

const products = data.data;

class PrimaryProduct extends Component {
  render() {
    const { num, title_top, title_bottom } = this.props;
    const num1 = parseInt(num) + 1;

    return (
      <div className="container-fluid product-display">
        <div className="product-image">
          <img src={img1} alt="..." />
        </div>
        <div className=" par">
          <p className="paragraph">
            Okamura, tuvo sus inicios en 1945, con sede en la ciudad de Okamura
            en Yokohama. Nuestra fortaleza radica en un amplio conocimiento y
            experiencia técnica en muchos campos, la base para crear espacios
            cómodos para clientes de todo el mundo.
          </p>
          <p className="paragraph">
            Una marca de origen japonés con más de 60 años en el mercado que
            trabaja con diseñadores como Giorgetto Giugiaro fundador de Giugiaro
            Design, que da como resultado colaboraciones entre diseño italiano y
            tecnologia japonesa.
          </p>
          <p className="paragraph">
            Desde el concepto hasta su resultado final, los productos de Okamura
            están llenos de espíritu creativo. Okamura está entre las 3 primeras
            empresas de de fabricación de muebles de oficina a nivel mundial.
          </p>
        </div>
      </div>
    );
  }
}

export default PrimaryProduct;
