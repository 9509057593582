import React, { Component, Fragment } from "react";
import Banner3 from "../components/Banner/Banner3";
import Us from "../components/Us/Us";
import Footer from "../components/Footer/Footer";


class History extends Component {
  render() {
    return (
      <Fragment>
        <Banner3 />
        <Us />
        <Footer />
      </Fragment>
    );
  }
}

export default History;
