// React
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Router } from 'react-router';
import createHistory from 'history/createBrowserHistory';

// Components
import App from './App';

// Styles
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';

const history = createHistory();

ReactDOM.render(
    <Router history={ history }>
      <App />
    </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (module.hot) {
  module.hot.accept()
}