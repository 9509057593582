import React from "react";

import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import "./Footer.css";

export default function Footer() {
  return (
    <div className="section-footer">
      <Container maxWidth="lg">
        <div className="container-columns">
          <div>
            <div>
              <h2>
                <HashLink to="/#products">PRODUCTOS</HashLink>
              </h2>
            </div>
            <div>
              <h2>
                <Link to="/History">NOSOTROS</Link>
              </h2>
            </div>
            <div>
              <h2>
                <Link to="/files/Garantia.pdf" target="_blank">
                  GARANTÍA
                </Link>
              </h2>
            </div>
          </div>

          <div>
            <div>
              <h2>
                <Link to="/files/AvisoPrivacidad.pdf" target="_blank">
                  AVISO DE PRIVACIDAD
                </Link>
              </h2>
            </div>
            <div>
              <h2>
                <Link to="/files/CodigoEtica.pdf" target="_blank">
                  CÓDIGO DE ÉTICA
                </Link>
              </h2>
            </div>
            <div>
              <h2>COPYRIGHT ©2023 ALL RIGHTS RESERVED GRUPO REQUIEZ.</h2>
            </div>
          </div>

          <div>
            <h2>CONTACTO</h2>
            <div>
              <h2>
                <a href="mailto:hola@gruporequiez.com">hola@gruporequiez.com</a>
              </h2>
            </div>
            <div>
              <h2>
                <a href="tel:+523338337788">+52 (33) 3833 7788</a>
              </h2>
            </div>
            <div className="social-icons">
              <a
                target="_blank"
                href="https://www.instagram.com/okamura_mx/"
                rel="noreferrer"
              >
                <InstagramIcon />
              </a>
              <a
                target="_blank"
                href="https://www.facebook.com/OkamuraMexico"
                rel="noreferrer"
              >
                <FacebookIcon />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
