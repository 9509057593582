import React from 'react'

const About = () => {
  return (
    <>
    <h1>Hola desde about</h1>
    </>
  )
}

export default About